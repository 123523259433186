import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0e433b93 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _390462d8 = () => interopDefault(import('../pages/marketplace/index.vue' /* webpackChunkName: "pages/marketplace/index" */))
const _440c10c2 = () => interopDefault(import('../pages/members.vue' /* webpackChunkName: "pages/members" */))
const _34780b0c = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _2be754c7 = () => interopDefault(import('../pages/team.vue' /* webpackChunkName: "pages/team" */))
const _735a2ad2 = () => interopDefault(import('../pages/theater.vue' /* webpackChunkName: "pages/theater" */))
const _697d7296 = () => interopDefault(import('../pages/theater-two.vue' /* webpackChunkName: "pages/theater-two" */))
const _64d38afa = () => interopDefault(import('../pages/account/demographics.vue' /* webpackChunkName: "pages/account/demographics" */))
const _3f09bd34 = () => interopDefault(import('../pages/account/details.vue' /* webpackChunkName: "pages/account/details" */))
const _8b2bdfb6 = () => interopDefault(import('../pages/account/events.vue' /* webpackChunkName: "pages/account/events" */))
const _6724a809 = () => interopDefault(import('../pages/account/my-products.vue' /* webpackChunkName: "pages/account/my-products" */))
const _6438ad4d = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _07382da2 = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _5db5e334 = () => interopDefault(import('../pages/account/speaker_application.vue' /* webpackChunkName: "pages/account/speaker_application" */))
const _2afd1238 = () => interopDefault(import('../pages/events/create.vue' /* webpackChunkName: "pages/events/create" */))
const _a3a19964 = () => interopDefault(import('../pages/events/past.vue' /* webpackChunkName: "pages/events/past" */))
const _5c945158 = () => interopDefault(import('../pages/events/upcoming.vue' /* webpackChunkName: "pages/events/upcoming" */))
const _661c71ac = () => interopDefault(import('../pages/marketplace/created.vue' /* webpackChunkName: "pages/marketplace/created" */))
const _188d2152 = () => interopDefault(import('../pages/marketplace/edited.vue' /* webpackChunkName: "pages/marketplace/edited" */))
const _626c9da2 = () => interopDefault(import('../pages/posts/create.vue' /* webpackChunkName: "pages/posts/create" */))
const _631a0a38 = () => interopDefault(import('../pages/account/company/marketing.vue' /* webpackChunkName: "pages/account/company/marketing" */))
const _53a5b28a = () => interopDefault(import('../pages/account/company/profile.vue' /* webpackChunkName: "pages/account/company/profile" */))
const _383ffd9c = () => interopDefault(import('../pages/account/company/speakers.vue' /* webpackChunkName: "pages/account/company/speakers" */))
const _5d014bcc = () => interopDefault(import('../pages/account/company/users.vue' /* webpackChunkName: "pages/account/company/users" */))
const _66c02db8 = () => interopDefault(import('../pages/marketplace/product/_mode.vue' /* webpackChunkName: "pages/marketplace/product/_mode" */))
const _2ccc1de8 = () => interopDefault(import('../pages/companies/_id.vue' /* webpackChunkName: "pages/companies/_id" */))
const _f2ee22f0 = () => interopDefault(import('../pages/conferences/_id.vue' /* webpackChunkName: "pages/conferences/_id" */))
const _ad832d9e = () => interopDefault(import('../pages/events/_id/index.vue' /* webpackChunkName: "pages/events/_id/index" */))
const _3f9665fc = () => interopDefault(import('../pages/marketplace/_id.vue' /* webpackChunkName: "pages/marketplace/_id" */))
const _075267b8 = () => interopDefault(import('../pages/pages/_id/index.vue' /* webpackChunkName: "pages/pages/_id/index" */))
const _7261a4f2 = () => interopDefault(import('../pages/posts/_id/index.vue' /* webpackChunkName: "pages/posts/_id/index" */))
const _0773362e = () => interopDefault(import('../pages/speakers/_id.vue' /* webpackChunkName: "pages/speakers/_id" */))
const _34e34ffb = () => interopDefault(import('../pages/events/_id/edit.vue' /* webpackChunkName: "pages/events/_id/edit" */))
const _4c2e9840 = () => interopDefault(import('../pages/events/_id/portal/index.vue' /* webpackChunkName: "pages/events/_id/portal/index" */))
const _0daff24c = () => interopDefault(import('../pages/events/_id/present.vue' /* webpackChunkName: "pages/events/_id/present" */))
const _4fbdb2b0 = () => interopDefault(import('../pages/events/_id/report/index.vue' /* webpackChunkName: "pages/events/_id/report/index" */))
const _d8f68c94 = () => interopDefault(import('../pages/events/_id/theatre.vue' /* webpackChunkName: "pages/events/_id/theatre" */))
const _4e9da8e5 = () => interopDefault(import('../pages/posts/_id/edit.vue' /* webpackChunkName: "pages/posts/_id/edit" */))
const _5bd83d6a = () => interopDefault(import('../pages/events/_id/portal/attendance.vue' /* webpackChunkName: "pages/events/_id/portal/attendance" */))
const _251f6302 = () => interopDefault(import('../pages/events/_id/portal/post.vue' /* webpackChunkName: "pages/events/_id/portal/post" */))
const _eb62c030 = () => interopDefault(import('../pages/events/_id/portal/registrations.vue' /* webpackChunkName: "pages/events/_id/portal/registrations" */))
const _a5591108 = () => interopDefault(import('../pages/events/_id/portal/views.vue' /* webpackChunkName: "pages/events/_id/portal/views" */))
const _56c828fa = () => interopDefault(import('../pages/events/_id/report/attendance.vue' /* webpackChunkName: "pages/events/_id/report/attendance" */))
const _efad118c = () => interopDefault(import('../pages/events/_id/report/post.vue' /* webpackChunkName: "pages/events/_id/report/post" */))
const _1f6bcab0 = () => interopDefault(import('../pages/events/_id/report/registrations.vue' /* webpackChunkName: "pages/events/_id/report/registrations" */))
const _a8e82b78 = () => interopDefault(import('../pages/events/_id/report/views.vue' /* webpackChunkName: "pages/events/_id/report/views" */))
const _ee8e0c8e = () => interopDefault(import('../pages/events/_id/calendar/_type.vue' /* webpackChunkName: "pages/events/_id/calendar/_type" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0e433b93,
    name: "about"
  }, {
    path: "/marketplace",
    component: _390462d8,
    name: "marketplace"
  }, {
    path: "/members",
    component: _440c10c2,
    name: "members"
  }, {
    path: "/signin",
    component: _34780b0c,
    name: "signin"
  }, {
    path: "/team",
    component: _2be754c7,
    name: "team"
  }, {
    path: "/theater",
    component: _735a2ad2,
    name: "theater"
  }, {
    path: "/theater-two",
    component: _697d7296,
    name: "theater-two"
  }, {
    path: "/account/demographics",
    component: _64d38afa,
    name: "account-demographics"
  }, {
    path: "/account/details",
    component: _3f09bd34,
    name: "account-details"
  }, {
    path: "/account/events",
    component: _8b2bdfb6,
    name: "account-events"
  }, {
    path: "/account/my-products",
    component: _6724a809,
    name: "account-my-products"
  }, {
    path: "/account/profile",
    component: _6438ad4d,
    name: "account-profile"
  }, {
    path: "/account/settings",
    component: _07382da2,
    name: "account-settings"
  }, {
    path: "/account/speaker_application",
    component: _5db5e334,
    name: "account-speaker_application"
  }, {
    path: "/events/create",
    component: _2afd1238,
    name: "events-create"
  }, {
    path: "/events/past",
    component: _a3a19964,
    name: "events-past"
  }, {
    path: "/events/upcoming",
    component: _5c945158,
    name: "events-upcoming"
  }, {
    path: "/marketplace/created",
    component: _661c71ac,
    name: "marketplace-created"
  }, {
    path: "/marketplace/edited",
    component: _188d2152,
    name: "marketplace-edited"
  }, {
    path: "/posts/create",
    component: _626c9da2,
    name: "posts-create"
  }, {
    path: "/account/company/marketing",
    component: _631a0a38,
    name: "account-company-marketing"
  }, {
    path: "/account/company/profile",
    component: _53a5b28a,
    name: "account-company-profile"
  }, {
    path: "/account/company/speakers",
    component: _383ffd9c,
    name: "account-company-speakers"
  }, {
    path: "/account/company/users",
    component: _5d014bcc,
    name: "account-company-users"
  }, {
    path: "/marketplace/product/:mode?",
    component: _66c02db8,
    name: "marketplace-product-mode"
  }, {
    path: "/companies/:id?",
    component: _2ccc1de8,
    name: "companies-id"
  }, {
    path: "/conferences/:id?",
    component: _f2ee22f0,
    name: "conferences-id"
  }, {
    path: "/events/:id",
    component: _ad832d9e,
    name: "events-id"
  }, {
    path: "/marketplace/:id",
    component: _3f9665fc,
    name: "marketplace-id"
  }, {
    path: "/pages/:id",
    component: _075267b8,
    name: "pages-id"
  }, {
    path: "/posts/:id",
    component: _7261a4f2,
    name: "posts-id"
  }, {
    path: "/speakers/:id?",
    component: _0773362e,
    name: "speakers-id"
  }, {
    path: "/events/:id?/edit",
    component: _34e34ffb,
    name: "events-id-edit"
  }, {
    path: "/events/:id?/portal",
    component: _4c2e9840,
    name: "events-id-portal"
  }, {
    path: "/events/:id?/present",
    component: _0daff24c,
    name: "events-id-present"
  }, {
    path: "/events/:id?/report",
    component: _4fbdb2b0,
    name: "events-id-report"
  }, {
    path: "/events/:id?/theatre",
    component: _d8f68c94,
    name: "events-id-theatre"
  }, {
    path: "/posts/:id?/edit",
    component: _4e9da8e5,
    name: "posts-id-edit"
  }, {
    path: "/events/:id?/portal/attendance",
    component: _5bd83d6a,
    name: "events-id-portal-attendance"
  }, {
    path: "/events/:id?/portal/post",
    component: _251f6302,
    name: "events-id-portal-post"
  }, {
    path: "/events/:id?/portal/registrations",
    component: _eb62c030,
    name: "events-id-portal-registrations"
  }, {
    path: "/events/:id?/portal/views",
    component: _a5591108,
    name: "events-id-portal-views"
  }, {
    path: "/events/:id?/report/attendance",
    component: _56c828fa,
    name: "events-id-report-attendance"
  }, {
    path: "/events/:id?/report/post",
    component: _efad118c,
    name: "events-id-report-post"
  }, {
    path: "/events/:id?/report/registrations",
    component: _1f6bcab0,
    name: "events-id-report-registrations"
  }, {
    path: "/events/:id?/report/views",
    component: _a8e82b78,
    name: "events-id-report-views"
  }, {
    path: "/events/:id?/calendar/:type?",
    component: _ee8e0c8e,
    name: "events-id-calendar-type"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/signup",
    component: _34780b0c,
    props: {"startMode":"signup"}
  }, {
    path: "/user/:username/verify",
    component: _34780b0c,
    props: {"startMode":"verify"}
  }, {
    path: "/forgot_password",
    component: _34780b0c,
    props: {"startMode":"forgot_password"}
  }, {
    path: "/user/:username/reset_password",
    component: _34780b0c,
    props: {"startMode":"reset_password"}
  }, {
    path: "/signout",
    component: _34780b0c,
    props: {"startMode":"signout"}
  }, {
    path: "/events/:id/preview",
    component: _ad832d9e,
    name: "preview"
  }, {
    path: "/posts/:id/preview",
    component: _7261a4f2,
    name: "preview-posts"
  }, {
    path: "/pages/:id/preview",
    component: _075267b8,
    name: "preview-pages"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
