






















































// @ts-ignore
import { ChatAltIcon, UserGroupIcon, UserIcon, CogIcon, ChevronRightIcon, FilmIcon } from '@vue-hero-icons/outline'
import { DateTime } from 'luxon'
import { Vue, Component } from 'nuxt-property-decorator'
import checkUrl from '~/middleware/checkUrl'

@Component({
  components: { ChatAltIcon, UserGroupIcon, UserIcon, CogIcon, ChevronRightIcon, FilmIcon },
  fetchDelay: 0,
  fetchKey: 'ReportLayout',
//   middleware: checkUrl,
//   head(...args: any) {
//     const url = args[0].$store.state.HOSTNAME
//     let script: any[] = []
//     if (url) {
//       if (url.includes('events.flaia.org')) {
//         console.log('IS EVENT PAGE')
//         script = [
//           { async: true, src: "https://www.googletagmanager.com/gtag/js?id=G-LZR78VGHG5" },
//           {
//             innerHTML: `window.dataLayer = window.dataLayer || [];
//           function gtag(){dataLayer.push(arguments);}
//           gtag('js', new Date());
//           gtag('config', 'G-LZR78VGHG5');`
//           }
//         ]
//       } else if (url.includes('flaia.org')) {
//         console.log('IS NORMAL PAGE')
//         script = [
//           { async: true, src: "https://www.googletagmanager.com/gtag/js?id=G-ZRR5D65W1L" },
//           {
//             innerHTML: `window.dataLayer = window.dataLayer || [];
//           function gtag(){dataLayer.push(arguments);}
//           gtag('js', new Date());
//           gtag('config', 'G-ZRR5D65W1L');`
//           }
//         ]
//       }
//     }
//     return {
//       script
//     }
//   }
})
export default class ReportLayout extends Vue {
  ended = false

  async fetch() {
    const event = await this.$strapi.findOne('events', this.$route.params.id)

    // Has event ended
    const endDate = DateTime.fromFormat(`${event.date} ${event.end_time}`, 'yyyy-M-d HH:mm:ss.SSS', { zone: 'America/New_York' })
    this.ended = endDate < DateTime.utc()
  }

  get slug() {
    return this.$route.params.id
  }
}
