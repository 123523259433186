import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Strapi } from '~/plugins/strapi'
import { IMarketplaceProduct, TFundtags, TRealEstateTags, TPeriod, MarketplaceProduct } from '~/types'

type TMarketplaceProductKeys = keyof IMarketplaceProduct

type TFundtagsKeys = keyof TFundtags

type TRealEstateTagsKeys = keyof TRealEstateTags

type TPeriodKeys = keyof TPeriod

@Module({
  name: 'marketplace',
  stateFactory: true,
  namespaced: true,
})
export default class MarketplaceStore extends VuexModule {
  signInAction: any = null

  isSignInRequired: boolean = false

  attachmentType: string = ''

  marketplaceProduct: IMarketplaceProduct = new MarketplaceProduct()

  allTags: any[] = []

  get signInActionData() {
    return this.signInAction
  }

  get attachmentTypeData() {
    return this.attachmentType
  }

  get marketplaceProductData() {
    return this.marketplaceProduct
  }

  get allTagsData() {
    return this.allTags
  }

  get isSignInRequiredData() {
    return this.isSignInRequired
  }

  @Mutation
  SET_SIGN_IN_ACTION(u: any) {
    this.signInAction = u
  }

  @Mutation
  SET_SIGN_IN_REQUIRED(u: boolean) {
    this.isSignInRequired = u
  }

  @Mutation
  SET_ATTACHMENT_TYPE(u: string) {
    this.attachmentType = u
  }

  @Mutation
  SET_PRODUCT_DATA<T extends TMarketplaceProductKeys>({ key, value }: { key: T; value: IMarketplaceProduct[T] }) {
    this.marketplaceProduct[key] = value
  }

  @Mutation
  SET_FUND_TAGS<T extends TFundtagsKeys>({ key, value }: { key: T; value: TFundtags[T] }) {
    this.marketplaceProduct.fundtags![key] = value
  }

  @Mutation
  SET_REAL_ESTATE_TAGS<T extends TRealEstateTagsKeys>({ key, value }: { key: T; value: TRealEstateTags[T] }) {
    this.marketplaceProduct.real_estate_tags![key] = value
  }

  @Mutation
  SET_REAL_ESTATE_TAGS_PERIOD<T extends TPeriodKeys>({ key, value }: { key: T; value: TPeriod[T] }) {
    this.marketplaceProduct.real_estate_tags!.period[key] = value
  }

  @Mutation
  ADD_VIDEOS(u: any) {
    this.marketplaceProduct.videos.push(u)
  }

  @Mutation
  REMOVE_VIDEO(youtubeId: string) {
    this.marketplaceProduct.videos = this.marketplaceProduct.videos.filter((video: any) => video.youtubeId !== youtubeId)
  }

  @Mutation
  ADD_ATTACHMENT(u: any) {
    this.marketplaceProduct.attachments.push(u)
  }

  @Mutation
  REMOVE_ATTACHMENT(fileId: string) {
    this.marketplaceProduct.attachments = this.marketplaceProduct.attachments.filter((attachment: any) => attachment?.id !== fileId)
  }

  @Mutation
  SET_MARKETPLACE_PRODUCT(u: IMarketplaceProduct) {
    this.marketplaceProduct = u
  }

  @Mutation
  SET_ALL_TAGS(u: any[]) {
    this.allTags = u
  }

  @Action({ rawError: true })
  setSignInAction(u: any) {
    this.SET_SIGN_IN_ACTION(u)
  }

  @Action
  setSignInRequired(u: boolean) {
    this.SET_SIGN_IN_REQUIRED(u)
  }

  @Action({ rawError: true })
  setAttachmentType(u: string) {
    this.SET_ATTACHMENT_TYPE(u)
  }

  @Action({ rawError: true })
  setProductData<T extends TMarketplaceProductKeys>({ key, value }: { key: T; value: IMarketplaceProduct[T] }) {
    this.SET_PRODUCT_DATA({ key, value })
  }

  @Action({ rawError: true })
  setFundTags<T extends TFundtagsKeys>({ key, value }: { key: T; value: TFundtags[T] }) {
    this.SET_FUND_TAGS({ key, value })
  }

  @Action({ rawError: true })
  setRealEstateTags<T extends TRealEstateTagsKeys>({ key, value }: { key: T; value: TRealEstateTags[T] }) {
    this.SET_REAL_ESTATE_TAGS({ key, value })
  }

  @Action({ rawError: true })
  setRealEstateTagsPeriod<T extends TPeriodKeys>({ key, value }: { key: T; value: TPeriod[T] }) {
    this.SET_REAL_ESTATE_TAGS_PERIOD({ key, value })
  }

  @Action({ rawError: true })
  addVideos(u: any) {
    this.ADD_VIDEOS(u)
  }

  @Action({ rawError: true })
  removeVideo(youtubeId: string) {
    this.REMOVE_VIDEO(youtubeId)
  }

  @Action({ rawError: true })
  addAttachments(u: any) {
    this.ADD_ATTACHMENT(u)
  }

  @Action({ rawError: true })
  removeAttachment(fileId: string) {
    this.REMOVE_ATTACHMENT(fileId)
  }

  @Action({ rawError: true })
  async fetchMarketplaceProduct({ id, $strapi }: { id: string; $strapi: Strapi }) {
    const data = await $strapi.$http.$get(`/marketplace-products/${id}`)
    this.SET_MARKETPLACE_PRODUCT(data)
  }

  @Action({ rawError: true })
  setMarketplaceProduct(u: IMarketplaceProduct) {
    this.SET_MARKETPLACE_PRODUCT(u)
  }

  @Action({ rawError: true })
  async fetchAllTags({ $strapi }: { $strapi: Strapi }) {
    const data = await $strapi.$tags.find({ _limit: '-1' })
    this.SET_ALL_TAGS(data)
  }

  @Action({ rawError: true })
  resetMarketplaceProduct() {
    this.SET_MARKETPLACE_PRODUCT(new MarketplaceProduct())
  }
}
