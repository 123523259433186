// not used directly but needed for typescript support
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { Context, Plugin } from '@nuxt/types'
import { ICompany, IConference, IEvent, IEventRegistration, IPost, ISpeakerProfile, IUser } from '~/types'
import { ISpeakerInterestApplications } from '~/types/speaker-interest-applications'

export interface Strapi {
  [x: string]: any
  $http: any
  $cookies: any
  user?: IUser
  hook: any
  clearToken(): void
  getToken(): string
  logout(): void
  fetchUser(): Promise<any>
  setToken(jwt: any): void
  login(params?: { identifier: string; password: string }): Promise<any>
  find(collection: string, params?: object): Promise<any>
  findOne(collection: string, id: number | string): Promise<any>
  create(collection: string, data: object): Promise<any>
  count(collection: string, params?: object): Promise<number>
  update(collection: string, id: number | string, data: object): Promise<any>
  delete(collection: string, id: number | string): Promise<any>
  $companies: CollectionFunctions<ICompany>
  $conferences: CollectionFunctions<IConference>
  $events: CollectionFunctions<IEvent>
  '$blog-posts': CollectionFunctions<IPost>
  '$event-registrations': CollectionFunctions<IEventRegistration>
  '$speaker-profiles': CollectionFunctions<ISpeakerProfile>
  '$speaker-interest-applications': CollectionFunctions<ISpeakerInterestApplications>
}

interface CollectionFunctions<T> {
  find(params?: object): Promise<T[]>
  findOne(id: number | string): Promise<T>
  create(data: object): Promise<T>
  count(params?: object): Promise<number>
  update(id: number | string, data: object): Promise<T>
  delete(id: number | string): Promise<T>
}

declare module 'vue/types/vue' {
  // this.$myInjectedFunction inside Vue components
  interface Vue {
    $strapi: Strapi
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$myInjectedFunction inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $strapi: Strapi
  }
  // nuxtContext.$myInjectedFunction
  interface Context {
    $strapi: Strapi
  }
}

declare module 'vuex/types/index' {
  // this.$myInjectedFunction inside Vuex stores
  interface Store<S> {
    $strapi: Strapi
  }
}

export default ({ $strapi, redirect }: Context) => {
  $strapi.hook('error', (error: any) => {
    if (error?.response?.data?.message === 'User Not Found') {
      $strapi.logout()
      $strapi.$cookies.remove('strapi_jwt', { path: '/', domain: process.env.cookieDomain })
      // redirect('/signout')
    }
  })
}
