import { IStrapiImage, IUnsplashImage, IUser, ICompany, IStrapiFile } from './'

export type TCompanyPDF = {
  title: string
  attachment: IStrapiFile
}

export type Tddq = {
  title: string
  attachment: IStrapiFile
}

export type TVideo = {
  title: string
  youtubeId: string
}

type TCompany = ICompany | number | undefined

export type TFundtags = {
  strategy_aum: string
  firm_aum: string
  length_of_track_record: string
  firm_inception: string
  law_firm_name: string
  audit_firm_name: string
  admin_firm_name: string
  structure_type: string
  can_download_materials: boolean
  upload_ddq: Tddq | null
  total_amount: string
  sec_or_states: string
  offering_exemption: string
}

export type TRealEstateTags = {
  total_amount: string
  asset_description: string
  offerings_launch_date: string
  aum_of_firm: string
  firm_inception: string
  law_firm: string
  audit_firm: string
  admin_firm: string
  period: TPeriod
  target_cashflow: string
}

export type TPeriod = {
  value: string
  types: string
}

export interface IMarketplaceProduct {
  Title: string
  description: string
  performance: string
  data1: string
  data2: string
  data3: string
  data4: string
  tags: Array<any>
  published_at: null
  image: Array<IStrapiImage | IUnsplashImage>
  creator: IUser | number | null
  company: TCompany
  company_investment_url: string
  attachments: Array<TCompanyPDF>
  videos: Array<TVideo>
  speaker_profiles: Array<number>
  fundtags: TFundtags | null
  real_estate_tags: TRealEstateTags | null
}

export interface IMarketplaceProductResponse {
  Title: string
  description: string
  performance: string
  data1: string
  data2: string
  data3: string
  data4: string
  slug: string
  tags: Array<any>
  image: Array<IStrapiImage | IUnsplashImage>
  company: ICompany
  company_investment_url: string
  attachments: Array<TCompanyPDF>
  videos: Array<TVideo>
  speaker_profiles: Array<any>
  fundtags: TFundtags | null
  real_estate_tags: TRealEstateTags | null
}

export class MarketplaceProduct implements IMarketplaceProduct {
  Title: string
  description: string
  performance: string
  data1: string
  data2: string
  data3: string
  data4: string
  tags: Array<any>
  published_at: null
  image: Array<IStrapiImage | IUnsplashImage>
  creator: IUser
  company_data: any
  company: ICompany
  company_investment_url: string
  attachments: Array<TCompanyPDF>
  videos: Array<TVideo>
  speaker_profiles: Array<number>
  fundtags: TFundtags | null
  real_estate_tags: TRealEstateTags | null

  constructor() {
    this.Title = ''
    this.description = ''
    this.performance = ''
    this.data1 = ''
    this.data2 = ''
    this.data3 = ''
    this.data4 = ''
    this.tags = []
    this.published_at = null
    this.image = []
    this.creator = {} as IUser
    this.company_data = {}
    this.company = {} as ICompany
    this.company_investment_url = ''
    this.attachments = []
    this.videos = []
    this.speaker_profiles = []
    this.fundtags = null
    this.real_estate_tags = null
  }
}

export class FundTags implements TFundtags {
  strategy_aum: string
  firm_aum: string
  length_of_track_record: string
  firm_inception: string
  law_firm_name: string
  audit_firm_name: string
  admin_firm_name: string
  structure_type: string
  can_download_materials: boolean
  upload_ddq: Tddq | null
  total_amount: string
  sec_or_states: string
  offering_exemption: string

  constructor(data: TFundtags = {} as TFundtags) {
    this.strategy_aum = data.strategy_aum || ''
    this.firm_aum = data.firm_aum || ''
    this.length_of_track_record = data.length_of_track_record || '1,Year(s)'
    this.firm_inception = data.firm_inception || '1,Year(s)'
    this.law_firm_name = data.law_firm_name || ''
    this.audit_firm_name = data.audit_firm_name || ''
    this.admin_firm_name = data.admin_firm_name || ''
    this.structure_type = data.structure_type || 'Limited Partnership'
    this.can_download_materials = data.can_download_materials || false
    this.upload_ddq = data.upload_ddq || null
    this.total_amount = data.total_amount || ''
    this.sec_or_states = data.sec_or_states || 'sec'
    this.offering_exemption = data.offering_exemption || 'None'
  }
}

export class RealEstateTags implements TRealEstateTags {
  total_amount: string
  asset_description: string
  offerings_launch_date: string
  aum_of_firm: string
  firm_inception: string
  law_firm: string
  audit_firm: string
  admin_firm: string
  period: {
    value: string
    types: string
  }

  target_cashflow: string

  constructor(data: TRealEstateTags = {} as TRealEstateTags) {
    this.total_amount = data.total_amount || ''
    this.asset_description = data.asset_description || ''
    this.offerings_launch_date = data.offerings_launch_date || ''
    this.aum_of_firm = data.aum_of_firm || ''
    this.firm_inception = data.firm_inception || ''
    this.law_firm = data.law_firm || ''
    this.audit_firm = data.audit_firm || ''
    this.admin_firm = data.admin_firm || ''
    this.period = data.period || { value: '1', types: 'Year' }
    this.target_cashflow = data.target_cashflow || ''
  }
}
