import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Strapi } from '~/plugins/strapi'

@Module({
  name: 'events',
  stateFactory: true,
  namespaced: true,
})
export default class EventsStore extends VuexModule {
  upcoming: any[] = []
  past: any[] = []

  get upcomingEventsConfs() {
    return this.upcoming
  }

  get pastEventsConfs() {
    return this.past
  }

  @Mutation
  SET_UPCOMING_EVENTS_CONFS(u: any[]) {
    this.upcoming = u
  }

  @Mutation
  SET_PAST_EVENTS_CONFS(u: any[]) {
    this.past = u
  }

  @Action({ rawError: true })
  async loadUpcomingEventsConfs({ strapi, companyId }: { strapi: Strapi; companyId: string }) {
    const query = companyId ? `?companyId=${companyId}` : ''
    const result = await strapi.$http.$get(`/events/upcomingEventsConfs${query}`)
    this.SET_UPCOMING_EVENTS_CONFS(result)
  }

  @Action({ rawError: true })
  async loadPastEventsConfs({ strapi, companyId }: { strapi: Strapi; companyId: string }) {
    const query = companyId ? `?companyId=${companyId}` : ''
    const result = await strapi.$http.$get(`/events/pastEventsConfs${query}`)
    this.SET_PAST_EVENTS_CONFS(result)
  }
}
