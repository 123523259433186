import { Context } from '@nuxt/types'
import { ITrackOptions, trackEvent } from '@/utils/analytics'
import { validateRoutePath } from '@/utils/validation'
import Cookie from 'cookie-universal'

export default function ({ from, req, res, route, $strapi }: Context) {
  if (!validateRoutePath(route.params.id)) {
    return
  }
  const referrer = (process.client ? window.document.referrer : req?.headers.referer) || from?.path
  const options: ITrackOptions = {
    referrer,
    user: $strapi.user
  }

  if (from) {
    const leaveOptions = Object.assign({}, options)
    delete leaveOptions.referrer
    trackEvent('pageLeave', from.path, leaveOptions)
  }

  if (process.server) {
    const cookies = Cookie(req, res)
    const userAgent = req?.headers?.['user-agent']
    const ip = req?.connection?.remoteAddress
    options.ip = ip
    options.userAgent = userAgent
    options.anonymous_id = cookies.get('flaia_id')
    trackEvent('pageView', route.path, options)
  } else {
    trackEvent('pageView', route.path, options)
  }
}
