import { IEvent } from './event'
import { ISpeakerProfile } from './speaker-profile'
import { IUser } from './user'
import { IStrapiFile } from './file'

export class FLAIA {
  strategy: string | null
  performance: string | null
  team: string | null

  constructor() {
    this.strategy = ''
    this.performance = ''
    this.team = ''
  }
}

/**
 * Model definition for Company
 */
export interface ICompany {
  id: number
  name: string
  description: string
  logo: IStrapiFile | null
  premium_level: 'None' | 'Premium'
  expiry_date?: Date
  market_link?: string
  speaker_profiles: ISpeakerProfile[]
  users: IUser[]
  events: IEvent[]
  investment_sectors?: { [key: string]: any }
  investment_types?: { [key: string]: any }
  firm_classification?: string
  investment_size?: string
  flaia: FLAIA | null
}
