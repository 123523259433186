

































































































































































// @ts-ignore
import { MenuIcon, XIcon, UserCircleIcon, UserIcon, ChevronRightIcon } from '@vue-hero-icons/solid'
import { Vue, Component, Watch } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import { IConference, IEvent } from '~/types'
import { eventType } from '~/utils/getEvents'

@Component({
  components: {
    MenuIcon,
    XIcon,
    UserCircleIcon,
    UserIcon,
    ChevronRightIcon,
  },
  fetchDelay: 0,
  fetchKey: 'Navigation',
  // fetchOnserver: false,
  mixins: [
    {
      methods: { eventType },
    },
  ],
  computed: {
    ...mapGetters('global', ['upcomingEvents']),
  },
})
export default class Navigation extends Vue {
  menuOpen = false
  profileOpen = false
  eventOpen = false
  aboutOpen = false
  profileImage: string | null = null
  upcomingEvents!: (IEvent | IConference)[]

  async fetch() {
    if (!this.$strapi.user?.speaker_profile) {
      this.profileImage = null
      return
    }
    try {
      let speaker_profile
      if (typeof this.$strapi.user.speaker_profile === 'object') {
        speaker_profile = this.$strapi.user.speaker_profile
      } else {
        speaker_profile = await this.$strapi.findOne('speaker-profiles', this.$strapi.user.speaker_profile)
      }
      const image = speaker_profile.headshot
      if (image) {
        const small_image = image.formats.xsmall || image
        this.profileImage = `${this.$config.assetsBaseUrl}/${encodeURIComponent(small_image.hash)}${encodeURIComponent(small_image.ext)}`
      } else {
        this.profileImage = null
      }
    } catch {
      this.profileImage = null
    }
  }

  hideProfileMenu() {
    this.profileOpen = false
  }

  hideEventMenu() {
    this.eventOpen = false
  }

  hideAboutMenu() {
    this.aboutOpen = false
  }

  @Watch('$route')
  onRouteChange() {
    this.eventOpen = false
    this.profileOpen = false
  }
}
