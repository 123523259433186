import { Context } from '@nuxt/types'
import { isEmpty } from 'lodash'
import dataProd from '../redirect-url.json'
import dataStaging from '../redirect-url.staging.json'

const newSiteMappings: any = {
//   '/': '/',
//   '/events/past': '/events?type=past',
//   '/events/upcoming': '/events?type=upcoming',
//   '/theater': '/theater',
//   '/signin': '/signin',
//   '/signup': '/signup',
//   '/forgot_password': '/forgot_password',
//   '/about': '/about',
//   '/team': '/about?type=teams',
//   '/members': '/about?type=members',
//   '/events/:id': '/events/:id',
//   '/conferences/:id': '/conferences/:id',
//   '/companies/:id': '/companies/:id',
//   '/speakers/:id': '/speakers/:id',
}

const data = process.env.production ? dataProd : dataStaging
export default function ({ redirect, route, $config }: Context) {
  const { path, params } = route

  let redirectedPath = ''
  if (isEmpty(params)) {
    redirectedPath = newSiteMappings[path as keyof typeof newSiteMappings]
  } else {
    const pathWithParams = Object.entries(params).reduce((acc, [k, v]) => {
      return path.replace(v, `:${k}`)
    }, path)
    const relatedMapping = newSiteMappings[pathWithParams as keyof typeof newSiteMappings]
    if (relatedMapping)
      redirectedPath = relatedMapping.replace(/\/:[^/]+/gi, (match: any) => {
        const paramsValue = params[match.substring(2)]
        return paramsValue ? `/${paramsValue}` : match
      })
  }

  if (redirectedPath) {
    redirect($config.newFlaiaSite + redirectedPath)
  }

  if (data.length > 0) {
    const protocol = 'https://'
    const redirectData = data.find((item: any) => item.URL === route.fullPath.replace(/\/$/, ''))
    if (redirectData) {
      return redirect(protocol + redirectData.RedirectDomain + redirectData.RedirectURL)
    }
  }
}
