export const DelayHydration = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c))
export const HydrationStatus = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c))
export const AttendanceChartWrapper = () => import('../../components/analytics/AttendanceChartWrapper.vue' /* webpackChunkName: "components/attendance-chart-wrapper" */).then(c => wrapFunctional(c.default || c))
export const AttendeeTableWrapper = () => import('../../components/analytics/AttendeeTableWrapper.vue' /* webpackChunkName: "components/attendee-table-wrapper" */).then(c => wrapFunctional(c.default || c))
export const DurationWidget = () => import('../../components/analytics/DurationWidget.vue' /* webpackChunkName: "components/duration-widget" */).then(c => wrapFunctional(c.default || c))
export const Filters = () => import('../../components/analytics/Filters.vue' /* webpackChunkName: "components/filters" */).then(c => wrapFunctional(c.default || c))
export const LineChart = () => import('../../components/analytics/LineChart.vue' /* webpackChunkName: "components/line-chart" */).then(c => wrapFunctional(c.default || c))
export const LineChartWrapper = () => import('../../components/analytics/LineChartWrapper.vue' /* webpackChunkName: "components/line-chart-wrapper" */).then(c => wrapFunctional(c.default || c))
export const NumberWidget = () => import('../../components/analytics/NumberWidget.vue' /* webpackChunkName: "components/number-widget" */).then(c => wrapFunctional(c.default || c))
export const PieChart = () => import('../../components/analytics/PieChart.vue' /* webpackChunkName: "components/pie-chart" */).then(c => wrapFunctional(c.default || c))
export const PieChartWrapper = () => import('../../components/analytics/PieChartWrapper.vue' /* webpackChunkName: "components/pie-chart-wrapper" */).then(c => wrapFunctional(c.default || c))
export const PostEventTableWrapper = () => import('../../components/analytics/PostEventTableWrapper.vue' /* webpackChunkName: "components/post-event-table-wrapper" */).then(c => wrapFunctional(c.default || c))
export const RegistrationsTableWrapper = () => import('../../components/analytics/RegistrationsTableWrapper.vue' /* webpackChunkName: "components/registrations-table-wrapper" */).then(c => wrapFunctional(c.default || c))
export const TableWidget = () => import('../../components/analytics/TableWidget.vue' /* webpackChunkName: "components/table-widget" */).then(c => wrapFunctional(c.default || c))
export const UserDetailsModal = () => import('../../components/analytics/UserDetailsModal.vue' /* webpackChunkName: "components/user-details-modal" */).then(c => wrapFunctional(c.default || c))
export const ViewsTableWrapper = () => import('../../components/analytics/ViewsTableWrapper.vue' /* webpackChunkName: "components/views-table-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ReportPageAttendeeTableWrapper = () => import('../../components/analytics/report/ReportPageAttendeeTableWrapper.vue' /* webpackChunkName: "components/report-page-attendee-table-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ReportPageLineChart = () => import('../../components/analytics/report/ReportPageLineChart.vue' /* webpackChunkName: "components/report-page-line-chart" */).then(c => wrapFunctional(c.default || c))
export const ReportPageLineChartWrapper = () => import('../../components/analytics/report/ReportPageLineChartWrapper.vue' /* webpackChunkName: "components/report-page-line-chart-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ReportPageNumberWidget = () => import('../../components/analytics/report/ReportPageNumberWidget.vue' /* webpackChunkName: "components/report-page-number-widget" */).then(c => wrapFunctional(c.default || c))
export const ReportPagePieChart = () => import('../../components/analytics/report/ReportPagePieChart.vue' /* webpackChunkName: "components/report-page-pie-chart" */).then(c => wrapFunctional(c.default || c))
export const ReportPagePieChartWrapper = () => import('../../components/analytics/report/ReportPagePieChartWrapper.vue' /* webpackChunkName: "components/report-page-pie-chart-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ReportPagePostEventTableWrapper = () => import('../../components/analytics/report/ReportPagePostEventTableWrapper.vue' /* webpackChunkName: "components/report-page-post-event-table-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ReportPageRegistrationsTableWrapper = () => import('../../components/analytics/report/ReportPageRegistrationsTableWrapper.vue' /* webpackChunkName: "components/report-page-registrations-table-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ReportPageTableWidget = () => import('../../components/analytics/report/ReportPageTableWidget.vue' /* webpackChunkName: "components/report-page-table-widget" */).then(c => wrapFunctional(c.default || c))
export const ReportPageViewsTableWrapper = () => import('../../components/analytics/report/ReportPageViewsTableWrapper.vue' /* webpackChunkName: "components/report-page-views-table-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ForgotPasswordForm = () => import('../../components/authentication/ForgotPasswordForm.vue' /* webpackChunkName: "components/forgot-password-form" */).then(c => wrapFunctional(c.default || c))
export const ResetPasswordForm = () => import('../../components/authentication/ResetPasswordForm.vue' /* webpackChunkName: "components/reset-password-form" */).then(c => wrapFunctional(c.default || c))
export const SignInForm = () => import('../../components/authentication/SignInForm.vue' /* webpackChunkName: "components/sign-in-form" */).then(c => wrapFunctional(c.default || c))
export const SignInModal = () => import('../../components/authentication/SignInModal.vue' /* webpackChunkName: "components/sign-in-modal" */).then(c => wrapFunctional(c.default || c))
export const SignUpForm = () => import('../../components/authentication/SignUpForm.vue' /* webpackChunkName: "components/sign-up-form" */).then(c => wrapFunctional(c.default || c))
export const AddSpeaker = () => import('../../components/editor/AddSpeaker.vue' /* webpackChunkName: "components/add-speaker" */).then(c => wrapFunctional(c.default || c))
export const AuthorSelector = () => import('../../components/editor/AuthorSelector.vue' /* webpackChunkName: "components/author-selector" */).then(c => wrapFunctional(c.default || c))
export const BulletsEditor = () => import('../../components/editor/BulletsEditor.vue' /* webpackChunkName: "components/bullets-editor" */).then(c => wrapFunctional(c.default || c))
export const DescriptionEditor = () => import('../../components/editor/DescriptionEditor.vue' /* webpackChunkName: "components/description-editor" */).then(c => wrapFunctional(c.default || c))
export const Editor = () => import('../../components/editor/Editor.vue' /* webpackChunkName: "components/editor" */).then(c => wrapFunctional(c.default || c))
export const Editor2 = () => import('../../components/editor/Editor2.vue' /* webpackChunkName: "components/editor2" */).then(c => wrapFunctional(c.default || c))
export const EditorCardLayout = () => import('../../components/editor/EditorCardLayout.vue' /* webpackChunkName: "components/editor-card-layout" */).then(c => wrapFunctional(c.default || c))
export const EditorIcon = () => import('../../components/editor/EditorIcon.vue' /* webpackChunkName: "components/editor-icon" */).then(c => wrapFunctional(c.default || c))
export const EditorSpeakerCard = () => import('../../components/editor/EditorSpeakerCard.vue' /* webpackChunkName: "components/editor-speaker-card" */).then(c => wrapFunctional(c.default || c))
export const HeaderSelector = () => import('../../components/editor/HeaderSelector.vue' /* webpackChunkName: "components/header-selector" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceDescriptionEditor = () => import('../../components/editor/MarketplaceDescriptionEditor.vue' /* webpackChunkName: "components/marketplace-description-editor" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceEditor = () => import('../../components/editor/MarketplaceEditor.vue' /* webpackChunkName: "components/marketplace-editor" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceEditorCardLayout = () => import('../../components/editor/MarketplaceEditorCardLayout.vue' /* webpackChunkName: "components/marketplace-editor-card-layout" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceHeaderSelector = () => import('../../components/editor/MarketplaceHeaderSelector.vue' /* webpackChunkName: "components/marketplace-header-selector" */).then(c => wrapFunctional(c.default || c))
export const PreRegistrationQuestionsPanel = () => import('../../components/editor/PreRegistrationQuestionsPanel.vue' /* webpackChunkName: "components/pre-registration-questions-panel" */).then(c => wrapFunctional(c.default || c))
export const ProgressBar = () => import('../../components/editor/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c))
export const SpeakerSelector = () => import('../../components/editor/SpeakerSelector.vue' /* webpackChunkName: "components/speaker-selector" */).then(c => wrapFunctional(c.default || c))
export const QuestionsQuestionRow = () => import('../../components/editor/questions/QuestionRow.vue' /* webpackChunkName: "components/questions-question-row" */).then(c => wrapFunctional(c.default || c))
export const QuestionsQuestionTextEdit = () => import('../../components/editor/questions/QuestionTextEdit.vue' /* webpackChunkName: "components/questions-question-text-edit" */).then(c => wrapFunctional(c.default || c))
export const CompanyFLAIAForm = () => import('../../components/user_profile_forms/CompanyFLAIAForm.vue' /* webpackChunkName: "components/company-f-l-a-i-a-form" */).then(c => wrapFunctional(c.default || c))
export const CompanyForm = () => import('../../components/user_profile_forms/CompanyForm.vue' /* webpackChunkName: "components/company-form" */).then(c => wrapFunctional(c.default || c))
export const CompanyProfileForm = () => import('../../components/user_profile_forms/CompanyProfileForm.vue' /* webpackChunkName: "components/company-profile-form" */).then(c => wrapFunctional(c.default || c))
export const PersonalInformationForm = () => import('../../components/user_profile_forms/PersonalInformationForm.vue' /* webpackChunkName: "components/personal-information-form" */).then(c => wrapFunctional(c.default || c))
export const SpeakerProfileForm = () => import('../../components/user_profile_forms/SpeakerProfileForm.vue' /* webpackChunkName: "components/speaker-profile-form" */).then(c => wrapFunctional(c.default || c))
export const UserDemographicsForm = () => import('../../components/user_profile_forms/UserDemographicsForm.vue' /* webpackChunkName: "components/user-demographics-form" */).then(c => wrapFunctional(c.default || c))
export const UserEmailForm = () => import('../../components/user_profile_forms/UserEmailForm.vue' /* webpackChunkName: "components/user-email-form" */).then(c => wrapFunctional(c.default || c))
export const UserPasswordForm = () => import('../../components/user_profile_forms/UserPasswordForm.vue' /* webpackChunkName: "components/user-password-form" */).then(c => wrapFunctional(c.default || c))
export const AddFileModal = () => import('../../components/preline-ui/AddFileModal.vue' /* webpackChunkName: "components/add-file-modal" */).then(c => wrapFunctional(c.default || c))
export const AddVideoModal = () => import('../../components/preline-ui/AddVideoModal.vue' /* webpackChunkName: "components/add-video-modal" */).then(c => wrapFunctional(c.default || c))
export const SocialShareDropdown = () => import('../../components/preline-ui/SocialShareDropdown.vue' /* webpackChunkName: "components/social-share-dropdown" */).then(c => wrapFunctional(c.default || c))
export const WatchYoutubeModal = () => import('../../components/preline-ui/WatchYoutubeModal.vue' /* webpackChunkName: "components/watch-youtube-modal" */).then(c => wrapFunctional(c.default || c))
export const AddAttachmentModal = () => import('../../components/AddAttachmentModal.vue' /* webpackChunkName: "components/add-attachment-modal" */).then(c => wrapFunctional(c.default || c))
export const AddSpeakerProfileForm = () => import('../../components/AddSpeakerProfileForm.vue' /* webpackChunkName: "components/add-speaker-profile-form" */).then(c => wrapFunctional(c.default || c))
export const AddUserPanel = () => import('../../components/AddUserPanel.vue' /* webpackChunkName: "components/add-user-panel" */).then(c => wrapFunctional(c.default || c))
export const CardLayout = () => import('../../components/CardLayout.vue' /* webpackChunkName: "components/card-layout" */).then(c => wrapFunctional(c.default || c))
export const CurrencyInput = () => import('../../components/CurrencyInput.vue' /* webpackChunkName: "components/currency-input" */).then(c => wrapFunctional(c.default || c))
export const EventCard = () => import('../../components/EventCard.vue' /* webpackChunkName: "components/event-card" */).then(c => wrapFunctional(c.default || c))
export const EventDetailsRow = () => import('../../components/EventDetailsRow.vue' /* webpackChunkName: "components/event-details-row" */).then(c => wrapFunctional(c.default || c))
export const EventListItem = () => import('../../components/EventListItem.vue' /* webpackChunkName: "components/event-list-item" */).then(c => wrapFunctional(c.default || c))
export const EventsSlider = () => import('../../components/EventsSlider.vue' /* webpackChunkName: "components/events-slider" */).then(c => wrapFunctional(c.default || c))
export const FloatingButton = () => import('../../components/FloatingButton.vue' /* webpackChunkName: "components/floating-button" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceCard = () => import('../../components/MarketplaceCard.vue' /* webpackChunkName: "components/marketplace-card" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const NotificationStack = () => import('../../components/NotificationStack.vue' /* webpackChunkName: "components/notification-stack" */).then(c => wrapFunctional(c.default || c))
export const Panel = () => import('../../components/Panel.vue' /* webpackChunkName: "components/panel" */).then(c => wrapFunctional(c.default || c))
export const PreRegistrationQuestionForm = () => import('../../components/PreRegistrationQuestionForm.vue' /* webpackChunkName: "components/pre-registration-question-form" */).then(c => wrapFunctional(c.default || c))
export const PreRegistrationQuestionsModal = () => import('../../components/PreRegistrationQuestionsModal.vue' /* webpackChunkName: "components/pre-registration-questions-modal" */).then(c => wrapFunctional(c.default || c))
export const ShareMenu = () => import('../../components/ShareMenu.vue' /* webpackChunkName: "components/share-menu" */).then(c => wrapFunctional(c.default || c))
export const SignInAlert = () => import('../../components/SignInAlert.vue' /* webpackChunkName: "components/sign-in-alert" */).then(c => wrapFunctional(c.default || c))
export const SimpleEventListItem = () => import('../../components/SimpleEventListItem.vue' /* webpackChunkName: "components/simple-event-list-item" */).then(c => wrapFunctional(c.default || c))
export const SkeletonParagraph = () => import('../../components/SkeletonParagraph.vue' /* webpackChunkName: "components/skeleton-paragraph" */).then(c => wrapFunctional(c.default || c))
export const SpeakerCard = () => import('../../components/SpeakerCard.vue' /* webpackChunkName: "components/speaker-card" */).then(c => wrapFunctional(c.default || c))
export const SpeakerCardSlim = () => import('../../components/SpeakerCardSlim.vue' /* webpackChunkName: "components/speaker-card-slim" */).then(c => wrapFunctional(c.default || c))
export const SpeakingInterestForm = () => import('../../components/SpeakingInterestForm.vue' /* webpackChunkName: "components/speaking-interest-form" */).then(c => wrapFunctional(c.default || c))
export const SubscribeBar = () => import('../../components/SubscribeBar.vue' /* webpackChunkName: "components/subscribe-bar" */).then(c => wrapFunctional(c.default || c))
export const TeamMember = () => import('../../components/TeamMember.vue' /* webpackChunkName: "components/team-member" */).then(c => wrapFunctional(c.default || c))
export const UserRow = () => import('../../components/UserRow.vue' /* webpackChunkName: "components/user-row" */).then(c => wrapFunctional(c.default || c))
export const WatchRecordingModal = () => import('../../components/WatchRecordingModal.vue' /* webpackChunkName: "components/watch-recording-modal" */).then(c => wrapFunctional(c.default || c))
export const CommonLoadingIndicator = () => import('../../components/common/LoadingIndicator.vue' /* webpackChunkName: "components/common-loading-indicator" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceAdditionalFields = () => import('../../components/marketplace/AdditionalFields.vue' /* webpackChunkName: "components/marketplace-additional-fields" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceCustomCard = () => import('../../components/marketplace/CustomCard.vue' /* webpackChunkName: "components/marketplace-custom-card" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceCustomPagination = () => import('../../components/marketplace/CustomPagination.vue' /* webpackChunkName: "components/marketplace-custom-pagination" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceHero = () => import('../../components/marketplace/Hero.vue' /* webpackChunkName: "components/marketplace-hero" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceLoadingSkeleton = () => import('../../components/marketplace/LoadingSkeleton.vue' /* webpackChunkName: "components/marketplace-loading-skeleton" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceSearch = () => import('../../components/marketplace/Search.vue' /* webpackChunkName: "components/marketplace-search" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceVideoField = () => import('../../components/marketplace/VideoField.vue' /* webpackChunkName: "components/marketplace-video-field" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceFormAttachmentUpload = () => import('../../components/marketplace/form/AttachmentUpload.vue' /* webpackChunkName: "components/marketplace-form-attachment-upload" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceFormFundTags = () => import('../../components/marketplace/form/FundTags.vue' /* webpackChunkName: "components/marketplace-form-fund-tags" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceFormProductDetail = () => import('../../components/marketplace/form/ProductDetail.vue' /* webpackChunkName: "components/marketplace-form-product-detail" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceFormRealEstateTags = () => import('../../components/marketplace/form/RealEstateTags.vue' /* webpackChunkName: "components/marketplace-form-real-estate-tags" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceFormTags = () => import('../../components/marketplace/form/Tags.vue' /* webpackChunkName: "components/marketplace-form-tags" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceFormVideoUpload = () => import('../../components/marketplace/form/VideoUpload.vue' /* webpackChunkName: "components/marketplace-form-video-upload" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
