import {
  BriefcaseIcon,
  ClockIcon,
  CalendarIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  FilmIcon,
  IdentificationIcon,
  PaperClipIcon,
  DocumentTextIcon,
  DocumentReportIcon,
  PhotographIcon,
  PresentationChartBarIcon,
  VolumeUpIcon,
  XCircleIcon,
  ExclamationCircleIcon,
  MailIcon,
  UserIcon,
  UserCircleIcon,
  // @ts-ignore
} from '@vue-hero-icons/solid'
import Vue from 'vue'

Object.entries({
  BriefcaseIcon,
  ClockIcon,
  CalendarIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  FilmIcon,
  IdentificationIcon,
  PaperClipIcon,
  DocumentTextIcon,
  DocumentReportIcon,
  PhotographIcon,
  PresentationChartBarIcon,
  VolumeUpIcon,
  XCircleIcon,
  ExclamationCircleIcon,
  MailIcon,
  UserIcon,
  UserCircleIcon,
}).forEach(([name, component]) => {
  Vue.component(name, component)
})
