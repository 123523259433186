import { Context } from '@nuxt/types'

export default async function ({ $strapi, route, redirect }: Context) {
  const product_id: any = route.query.id
  const selected_product = await $strapi.$http.$get('/marketplace-products?id=' + product_id)

  let selected_product_company_id: any = null
  let logged_user_company_id: any = null

  if (selected_product.company_data) {
    selected_product_company_id = selected_product.company_data?.company_id
    logged_user_company_id = $strapi.user?.company
  }

  if (selected_product.company) {
    selected_product_company_id = selected_product.company?.id
    logged_user_company_id = $strapi.user?.company
  }
  // If the selected product company id isn't the same than logged user company id, redirect.
  if (selected_product_company_id !== logged_user_company_id) {
    return redirect('/account/my-products')
  }
}
