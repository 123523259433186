








import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component({
  components: {},
  fetchDelay: 0,
  head() {
    return {
      title: '404 - Not Found',
    }
  },
})
export default class Error extends Vue {
  @Prop() error!: Error
  @Prop({ default: "We can't find what you are looking for" }) errorMessage!: string

  async mounted() {}
}
