import { Context } from '@nuxt/types'
import { Store } from 'vuex'
import { initialiseStores, globalStore } from '~/utils/store-accessor'
import { validateRoutePath } from '@/utils/validation'


const initializer = (store: Store<any>) => initialiseStores(store)

const getPathURL = () => {
  if (typeof window !== "undefined" && window && window.location && window.location.pathname) {
    let pathName = []
    if (window.location.pathname.includes("events") || window.location.pathname.includes("conferences")) {
      pathName = window.location.pathname.split("/")
      if (pathName.length == 3) {
        console.log("find events path", pathName)
        return pathName[pathName.length - 1]
      }
    }
  }
}

export const actions = {
  async nuxtServerInit(_: any, { store, $strapi, payload }: Context) {
    initialiseStores(store)
    if (payload && payload.store) {
      globalStore.SET_UPCOMING(payload.store.upcoming)
      globalStore.SET_PAST(payload.store.past)
    } else {
      if (!validateRoutePath(getPathURL())) {
        return
      }
      await globalStore.loadEvents($strapi)
    }
  },
}

export const plugins = [initializer]
export * from '~/utils/store-accessor'

