

































































import { Vue, Component } from 'nuxt-property-decorator'
import { analyticsStore } from '@/store'
import { mapGetters } from 'vuex'
import { ISignInUp, IUser } from '~/types'

@Component({
  components: {},
  fetchDelay: 0,
  fetchKey: 'UserDetailsModal',
  computed: {
    ...mapGetters('analytics', ['detailsUser']),
  },
})
export default class UserDetailsModal extends Vue {
  signUpData!: ISignInUp
  detailsUser!: IUser | null

  close() {
    analyticsStore.CLEAR_USER()
  }

  get investmentSize() {
    if (this.detailsUser === null || this.detailsUser.investment_size === undefined || this.detailsUser.investment_size === null) {
      return ''
    }
    return this.signUpData.investment_size_options.items.find((i) => i.name === this.detailsUser?.investment_size)?.label
  }

  get firmClassification() {
    if (this.detailsUser === null || this.detailsUser.firm_classification === undefined || this.detailsUser.firm_classification === null) {
      return ''
    }
    return this.signUpData.firm_classification_options.items.find((i) => i.name === this.detailsUser?.firm_classification)?.label
  }

  get investmentSectors() {
    if (this.detailsUser === null || this.detailsUser.investment_sectors === undefined || this.detailsUser.investment_sectors === null) {
      return []
    }
    return this.detailsUser.investment_sectors.map(
      (s: string) => this.signUpData.investment_sector_options.items.find((i) => i.name === s)?.label
    )
  }

  get investmentTypes() {
    if (this.detailsUser === null || this.detailsUser.investment_types === undefined || this.detailsUser.investment_types === null) {
      return []
    }
    return this.detailsUser.investment_types.map(
      (s: string) => this.signUpData.investment_type_options.items.find((i) => i.name === s)?.label
    )
  }

  async fetch() {
    this.signUpData = await this.$strapi.find('sign-in-up')
  }

  get shown() {
    return this.detailsUser !== null && !this.$fetchState.pending
  }
}
