import { render, staticRenderFns } from "./portal.vue?vue&type=template&id=3a65066e&lang=pug&"
import script from "./portal.vue?vue&type=script&lang=ts&"
export * from "./portal.vue?vue&type=script&lang=ts&"
import style0 from "./portal.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navigation: require('/app/components/Navigation.vue').default,NotificationStack: require('/app/components/NotificationStack.vue').default,UserDetailsModal: require('/app/components/analytics/UserDetailsModal.vue').default,Footer: require('/app/components/Footer.vue').default})
