import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.directive('validate', {
  bind(el, binding, vnode) {
    // @ts-ignore
    if (vnode.context && vnode.context.$v && vnode.context.$v.validationEnabled === undefined) {
      // @ts-ignore
      vnode.context.$v.validationEnabled = {}
    }

    let timeout: number

    el.addEventListener('blur', function (_event) {
      const validationName = binding.arg
      if (vnode.context !== undefined && validationName) {
        const validation = vnode.context.$v[validationName]
        timeout = window.setTimeout(() => {
          validation.$touch()
          if (validation.$invalid) {
            // @ts-ignore
            vnode.context.$set(vnode.context.$v.validationEnabled, validationName, true)
          }
        }, 100)
      }
    })

    el.addEventListener('focus', function (_event) {
      clearTimeout(timeout)
    })

    el.addEventListener('input', function (_event) {
      const validationName = binding.arg
      if (vnode.context !== undefined && validationName) {
        const validation = vnode.context.$v[validationName]
        // @ts-ignore
        if (!vnode.context.$v.validationEnabled[validationName]) {
          validation.$reset()
        }
        if (!validation.$invalid) {
          // @ts-ignore
          vnode.context.$set(vnode.context.$v.validationEnabled, validationName, false)
        }
      }
    })
  },
  update(el, binding, vnode) {
    const validationName = binding.arg
    if (vnode.context !== undefined && validationName) {
      const validation = vnode.context.$v[validationName]
      el.classList.toggle('border-red-300', validation.$error)
    }
  },
})
