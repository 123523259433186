
























































































































// @ts-ignore
import { ChatAltIcon, UserGroupIcon, UserIcon, CogIcon, ChevronRightIcon, ChartPieIcon } from '@vue-hero-icons/outline'
import { Vue, Component, Watch } from 'nuxt-property-decorator'
import checkUrl from '~/middleware/checkUrl'

@Component({
  components: { ChatAltIcon, UserGroupIcon, UserIcon, CogIcon, ChevronRightIcon, ChartPieIcon },
//   middleware: checkUrl,
//   head(...args: any) {
//     const url = args[0].$store.state.HOSTNAME
//     let script: any[] = []
//     if (url) {
//       if (url.includes('events.flaia.org')) {
//         console.log('IS EVENT PAGE')
//         script = [
//           { async: true, src: "https://www.googletagmanager.com/gtag/js?id=G-LZR78VGHG5" },
//           {
//             innerHTML: `window.dataLayer = window.dataLayer || [];
//           function gtag(){dataLayer.push(arguments);}
//           gtag('js', new Date());
//           gtag('config', 'G-LZR78VGHG5');`
//           }
//         ]
//       } else if (url.includes('flaia.org')) {
//         console.log('IS NORMAL PAGE')
//         script = [
//           { async: true, src: "https://www.googletagmanager.com/gtag/js?id=G-ZRR5D65W1L" },
//           {
//             innerHTML: `window.dataLayer = window.dataLayer || [];
//           function gtag(){dataLayer.push(arguments);}
//           gtag('js', new Date());
//           gtag('config', 'G-ZRR5D65W1L');`
//           }
//         ]
//       }
//     }
//     return {
//       script
//     }
//   }
})
export default class AccountLayout extends Vue {
  companyMenu = false
  company: any = null

  mounted() {
    this.company = this.$strapi.user?.company_name
  }

  created() {
    if (this.$route.path.startsWith('/account/company/')) {
      this.companyMenu = true
    }
  }

  @Watch('$route')
  onRouteChange() {
    if (this.$route.path.startsWith('/account/company/')) {
      this.companyMenu = true
    }
  }
}
