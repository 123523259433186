import { Context } from '@nuxt/types'

export default async function ({ $strapi, redirect }: Context) {
  // If the user is not authenticated check for token
  if (!$strapi.user) {
    await $strapi.fetchUser()
  }
  // If still not authenticated redirect to signin
  if (!$strapi.user) {
    return redirect('/signin')
  }
  // If no company redirect to company profile create page
  if ($strapi.user.company === null || $strapi.user.company === undefined) {
    return redirect('/account/company/profile', { error: 'NoCompany' })
  }
}
