import { Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import { Strapi } from '~/plugins/strapi'
import { IConference, IEvent } from '~/types'
import { getPastEvents, getUpcomingEvents } from '~/utils/getEvents'

@Module({
  name: 'global',
  stateFactory: true,
  namespaced: true,
})
export default class GlobalStore extends VuexModule {
  upcoming: (IEvent | IConference)[] = []
  past: (IEvent | IConference)[] = []

  @Mutation
  SET_UPCOMING(u: (IEvent | IConference)[]) {
    this.upcoming = u
  }

  @Mutation
  SET_PAST(u: (IEvent | IConference)[]) {
    this.past = u
  }

  @MutationAction({ mutate: ['upcoming', 'past'] })
  async loadEvents($strapi: Strapi) {
    const upcomingEventsPromise = getUpcomingEvents($strapi)
    const pastEventsPromise = getPastEvents($strapi)

    const [upcoming, past] = await Promise.all([upcomingEventsPromise, pastEventsPromise])
    return { upcoming, past }
  }

  get upcomingEvents() {
    return this.upcoming
  }

  get pastEvents() {
    return this.past
  }
}
