/* eslint-disable import/no-mutable-exports */
import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import GlobalStore from '~/store/global'
import EditorStore from '~/store/editor'
import PostEditorStore from '~/store/postEditor'
import NotificationsStore from '~/store/notifications'
import AnalyticsStore from '~/store/analytics'
import EventsStore from '~/store/events'
import MarketplaceStore from '~/store/marketplace'
import AttachmentModalStore from '~/store/attachmentModal'

let globalStore: GlobalStore
let editorStore: EditorStore
let notificationsStore: NotificationsStore
let analyticsStore: AnalyticsStore
let postEditorStore: PostEditorStore
let eventsStore: EventsStore
let marketplaceStore: MarketplaceStore
let attachmentModalStore: AttachmentModalStore

function initialiseStores(store: Store<any>): void {
  globalStore = getModule(GlobalStore, store)
  editorStore = getModule(EditorStore, store)
  notificationsStore = getModule(NotificationsStore, store)
  analyticsStore = getModule(AnalyticsStore, store)
  postEditorStore = getModule(PostEditorStore, store)
  eventsStore = getModule(EventsStore, store)
  marketplaceStore = getModule(MarketplaceStore, store)
  attachmentModalStore = getModule(AttachmentModalStore, store)
}

export {
  initialiseStores,
  globalStore,
  editorStore,
  notificationsStore,
  analyticsStore,
  postEditorStore,
  eventsStore,
  marketplaceStore,
  attachmentModalStore,
}
