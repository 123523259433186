import { Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'

export interface INotification {
  id: number
  type: 'success' | 'warning' | 'error' | 'information'
  title?: string
  content: string
  duration?: number
}

@Module({
  name: 'notifications',
  stateFactory: true,
  namespaced: true,
})
export default class NotificationsStore extends VuexModule {
  stack: INotification[] = []
  idCounter = 1

  @Action
  pushNotification(notification: Omit<INotification, 'id'>) {
    const id = this.idCounter
    const toAdd = {
      id,
      ...notification,
    }
    this.ADD_NOTIFICATION(toAdd)
    this.INCREMENT_COUNTER()

    if (notification.duration) {
      setTimeout(() => {
        const index = this.stack.findIndex((n) => n.id === id)
        if (index >= 0) this.REMOVE_NOTIFICATION(index)
      }, notification.duration)
    }
  }

  @Mutation
  INCREMENT_COUNTER() {
    this.idCounter++
  }

  @Mutation
  ADD_NOTIFICATION(notification: INotification) {
    this.stack.push(notification)
  }

  @Mutation
  REMOVE_NOTIFICATION(index: number) {
    this.stack.splice(index, 1)
  }

  get notifications(): INotification[] {
    return this.stack
  }
}
