import { Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import { IUser } from '~/types'
@Module({
  name: 'analytics',
  stateFactory: true,
  namespaced: true,
})
export default class AnalyticsStore extends VuexModule {
  user: IUser | null = null

  @Mutation
  SET_USER(user: IUser) {
    this.user = user
  }

  @Mutation
  CLEAR_USER() {
    this.user = null
  }

  get detailsUser() {
    return this.user
  }
}
