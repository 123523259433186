import Vue from 'vue'
import { trackEvent } from '@/utils/analytics'
import { Context } from '@nuxt/types'
import { v4 as uuidv4 } from 'uuid'
import { CookieSerializeOptions } from 'cookie'
import Cookie from 'cookie-universal'
const cookies = Cookie()

const COOKIE_NAME = 'flaia_id'

Vue.directive('track', {
  bind(el, binding, vnode) {
    if (binding.modifiers.change) return
    el.addEventListener('click', function (_event) {
      const eventName = binding.arg
      const eventData = binding.value
      const referrer = window.document.referrer
      const { $route, $strapi } = vnode.context as any
      if (eventName) {
        trackEvent(eventName, $route.path, { referrer, user: $strapi.user, eventData })
      }
    })
  },
  update(_el, binding, vnode) {
    if (binding.modifiers.change) {
      const eventName = binding.arg
      const oldName = binding.oldArg
      const eventData = binding.value
      const referrer = window.document.referrer
      const { $route, $strapi } = vnode.context as any
      if (eventName && eventName !== oldName) {
        trackEvent(eventName, $route.path, { referrer, user: $strapi.user, eventData })
      }
    }
  },
})

export default ({ $strapi }: Context) => {
  let anonymous_id = cookies.get(COOKIE_NAME)
  if (!anonymous_id) {
    const cookieParams: CookieSerializeOptions = {
      sameSite: 'strict',
      path: '/',
      maxAge: 2147483647, // Y-2038
      domain: process.env.cookieDomain,
    }
    if (process.env.production) {
      cookieParams.secure = true
    }

    anonymous_id = uuidv4()
    cookies.set(COOKIE_NAME, anonymous_id, cookieParams)
  }
  $strapi.$http.setHeader('Flaia-ID', anonymous_id)
}
