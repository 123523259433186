import { Context } from '@nuxt/types'

export default async function ({ $strapi, redirect, error }: Context) {
  // If the user is not authenticated check for token
  if (!$strapi.user) {
    const token = $strapi.getToken()
    $strapi.$http.setToken(token, 'Bearer')
    $strapi.setToken(token)
    await $strapi.fetchUser()
  }

  // If still not authenticated redirect to signin
  if (!$strapi.user) {
    return redirect('/signin')
  }

  if ($strapi.user.role.name !== 'Admin') {
    return error({ statusCode: 403, message: 'Access Denied' })
  }
}
