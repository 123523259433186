import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
// @ts-ignore
import { getField, updateField } from 'vuex-map-fields'
import { ISpeakerProfile, ICompany, IPost, IStrapiImage, IUnsplashImage, IPage } from '~/types'

const steps: ((e: PostEditorStore) => boolean)[] = [
  (e) => !!e.title, // Has Title
  (e) => e.headerImage.length > 0, // Has Header Image
  (e) => !!e.date, // Has Date
  (e) => !!e.description, // Has Description
  (e) => e.bulletItems.length > 0 && e.bulletItems[0] !== null && e.bulletItems[0].text !== '', // Has at least one bullet
]

const progressMap = [10, 20, 20, 40, 10]
const labelsMap = ['Enter an Title', 'Add a Header Image', 'Choose a Date', 'Add a Description', 'Add some Bullet Points']

@Module({
  name: 'postEditor',
  stateFactory: true,
  namespaced: true,
})
export default class PostEditorStore extends VuexModule {
  title: string = ''
  date: Date | null = null
  description: string = ''
  bulletItems: { text: string }[] = []
  headerImage: [Omit<IStrapiImage, 'id'> | Omit<IUnsplashImage, 'id'>] | [] = []
  company: number | ICompany | null = null
  authors_t: ISpeakerProfile[] | [] = []

  get getField() {
    return getField(this)
  }

  @Mutation
  updateField(options: { path: string; value: unknown }) {
    return updateField(this, options)
  }

  @Mutation
  SET_TITLE(title: string) {
    this.title = title
  }

  @Mutation
  SET_DATE(date: Date | null) {
    this.date = date
  }

  @Mutation
  SET_DESCRIPTION(description: string) {
    this.description = description
  }

  @Mutation
  SET_BULLET_ITEMS(bulletItems: { text: string }[] | []) {
    this.bulletItems = bulletItems
  }

  @Mutation
  SET_HEADER_IMAGE(headerImage: [Omit<IStrapiImage, 'id'> | Omit<IUnsplashImage, 'id'>] | []) {
    this.headerImage = headerImage
  }

  @Mutation
  SET_COMPANY(company: number | null) {
    this.company = company
  }

  @Mutation
  SET_AUTHORS(authors: ISpeakerProfile[] | []) {
    this.authors_t = authors
  }

  @Mutation
  ADD_AUTHOR({ index, author }: { index: number; author: ISpeakerProfile }) {
    Vue.set(this.authors_t, index, author)
  }

  @Mutation
  REMOVE_AUTHOR({ index }: { index: number }) {
    Vue.delete(this.authors_t, index)
  }

  @Action
  setPost(post: IPost | IPage) {
    this.SET_TITLE(post.title)
    this.SET_DATE(post.date)
    this.SET_DESCRIPTION(post.description)
    this.SET_HEADER_IMAGE(post.header_image)
    if (post.author) this.SET_AUTHORS([post.author])
    const bulletItems = post.bullet_items.filter((i) => i.text !== undefined) as { text: string }[]
    this.SET_BULLET_ITEMS(bulletItems)
    if (post.company) this.SET_COMPANY(post.company.id)
  }

  get authors() {
    return this.authors_t
  }

  get currentStep() {
    for (const [i, func] of steps.entries()) {
      if (!func(this)) {
        return i + 1
      }
    }
    return steps.length + 1
  }

  get progressLabel() {
    return labelsMap[this.currentStep - 1]
  }

  get progressPercent(): number {
    let progress = 0
    for (const [i, func] of steps.entries()) {
      if (func(this)) {
        progress += progressMap[i]
      }
    }
    return progress
  }

  get httpParams() {
    return {
      title: this.title,
      date: this.date,
      description: this.description,
      bullet_items: this.bulletItems,
      header_image: this.headerImage,
      company: this.company,
      // slug: this.title.replace(' ', '-').toLowerCase(),
      authors: this.authors_t,
    }
  }
}
