


























// @ts-ignore
import { ExclamationCircleIcon, CheckCircleIcon, InformationCircleIcon, XCircleIcon, XIcon } from '@vue-hero-icons/solid'
import { Vue, Component } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import { notificationsStore } from '~/store'

@Component({
  components: {
    CheckCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
    XCircleIcon,
    XIcon,
  },
  fetchDelay: 0,
  computed: {
    ...mapGetters('notifications', ['notifications']),
  },
})
export default class NotificationStack extends Vue {
  close(index: number) {
    notificationsStore.REMOVE_NOTIFICATION(index)
  }

  defaultTitle(type: string) {
    switch (type) {
      case 'success':
        return 'Success!'
      case 'warning':
        return 'Warning!'
      case 'error':
        return 'Error'
      case 'information':
        return 'Information'
    }
  }

  iconClass(type: string) {
    switch (type) {
      case 'success':
        return 'text-green-400'
      case 'warning':
        return 'text-yellow-400'
      case 'error':
        return 'text-red-400'
      case 'information':
        return 'text-gray-400'
    }
  }

  bgClass(type: string) {
    switch (type) {
      case 'success':
        return 'bg-green-200'
      case 'warning':
        return 'bg-yellow-200'
      case 'error':
        return 'bg-red-200'
      case 'information':
        return 'bg-gray-200'
    }
  }

  icon(type: string) {
    switch (type) {
      case 'success':
        return 'CheckCircleIcon'
      case 'warning':
        return 'ExclamationCircleIcon'
      case 'error':
        return 'XCircleIcon'
      case 'information':
        return 'InformationCircleIcon'
    }
  }
}
