




































import { Vue, Component } from 'nuxt-property-decorator'
import { DateTime } from 'luxon'

@Component({
  fetchDelay: 0,
})
export default class Footer extends Vue {
  get currentYear() {
    return DateTime.utc().year
  }
}
