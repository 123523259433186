import { DateTime } from 'luxon'
import { Strapi } from '~/plugins/strapi'
import { IConference, IEvent } from '~/types'

export const getUpcomingEvents = async ($strapi: Strapi): Promise<(IEvent | IConference)[]> => {
  // Filter on null issue: https://github.com/strapi/strapi/issues/5414
  const eventsPromise = $strapi.$http.$get('/events/upcoming?conference_null=true&published_at_null=false&_publicationState=preview')
  const conferencesPromise = $strapi.$http.$get('/conferences/upcoming')

  const events = (await Promise.all([eventsPromise, conferencesPromise])).flat().sort((a, b) => {
    const aDate = DateTime.fromISO(a.date || a.start_time)
    const bDate = DateTime.fromISO(b.date || b.start_time)
    if (aDate < bDate) return -1
    if (aDate > bDate) return 1
    return 0
  })

  return events
}

export const getPastEvents = async ($strapi: Strapi): Promise<(IEvent | IConference)[]> => {
  // Filter on null issue: https://github.com/strapi/strapi/issues/5414
  const eventsPromise = $strapi.$http.$get('/events/past?conference_null=true&published_at_null=false&_publicationState=preview')
  const conferencesPromise = $strapi.$http.$get('/conferences/past')

  const events = (await Promise.all([eventsPromise, conferencesPromise])).flat().sort((a, b) => {
    const aDate = DateTime.fromISO(a.date || a.start_time)
    const bDate = DateTime.fromISO(b.date || b.start_time)
    if (aDate > bDate) return -1
    if (aDate < bDate) return 1
    return 0
  })

  return events
}

export const eventType = (event: IEvent | IConference): 'event' | 'conference' => {
  if ((event as IEvent).event_type === undefined) {
    return 'conference'
  } else {
    return 'event'
  }
}
