import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'attachmentModal',
  stateFactory: true,
  namespaced: true,
})
export default class AttachmentModalStore extends VuexModule {
  attachment: any = null
  isShow: boolean = false

  get attachmentData() {
    return this.attachment
  }

  @Mutation
  SET_IS_SHOW(u: boolean) {
    this.isShow = u
  }

  @Mutation
  SET_ATTACHMENT(u: any) {
    this.attachment = u
  }

  @Mutation
  RESET_ATTACHMENT() {
    this.attachment = null
  }

  @Action({ rawError: true })
  addAttachment(attachment: any) {
    this.SET_ATTACHMENT(attachment)
  }

  @Action({ rawError: true })
  async uploadAttachment({ file, title, $strapi }: { file: any; title: string; $strapi: any }) {
    const formData = new FormData()
    formData.append('files', file, title)
    const result = await $strapi.$http.$post('upload', formData)
    this.SET_ATTACHMENT({
      title,
      attachment: result[0],
    })
  }

  @Action({ rawError: true })
  openAttachmentModal() {
    this.SET_IS_SHOW(true)
  }

  @Action({ rawError: true })
  resetAttachment() {
    this.RESET_ATTACHMENT()
  }

  @Action({ rawError: true })
  closeAttachmentModal() {
    this.SET_IS_SHOW(false)
  }
}
