import { Context } from '@nuxt/types'
import Cookie from 'cookie-universal'

const cookies = Cookie()

export default async function ({ $strapi, redirect, route }: Context) {
  // If the user is not authenticated check for token
  if (!$strapi.user) {
    const token = $strapi.getToken()
    $strapi.$http.setToken(token, 'Bearer')
    $strapi.setToken(token)
    await $strapi.fetchUser()
  }
  // If still not authenticated redirect to signin
  if (!$strapi.user) {
    cookies.set('signup_redirect', route.fullPath)
    if (process.client) {
      return redirect('/signin')
    }
    return redirect('/signin', { redirect: route.fullPath })
  }
}
