const middleware = {}

middleware['analytics'] = require('../middleware/analytics.ts')
middleware['analytics'] = middleware['analytics'].default || middleware['analytics']

middleware['checkUrl'] = require('../middleware/checkUrl.ts')
middleware['checkUrl'] = middleware['checkUrl'].default || middleware['checkUrl']

middleware['hasCompany'] = require('../middleware/hasCompany.ts')
middleware['hasCompany'] = middleware['hasCompany'].default || middleware['hasCompany']

middleware['isAdmin'] = require('../middleware/isAdmin.ts')
middleware['isAdmin'] = middleware['isAdmin'].default || middleware['isAdmin']

middleware['isAuthenticated'] = require('../middleware/isAuthenticated.ts')
middleware['isAuthenticated'] = middleware['isAuthenticated'].default || middleware['isAuthenticated']

middleware['isCompanyAdmin'] = require('../middleware/isCompanyAdmin.ts')
middleware['isCompanyAdmin'] = middleware['isCompanyAdmin'].default || middleware['isCompanyAdmin']

middleware['isProductOwner'] = require('../middleware/isProductOwner.ts')
middleware['isProductOwner'] = middleware['isProductOwner'].default || middleware['isProductOwner']

middleware['redirects'] = require('../middleware/redirects.ts')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

export default middleware
