import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faMoneyBill1 as freeFarFaMoneyBill1,
  faFileLines as freeFarFaFileLines
} from '@fortawesome/free-regular-svg-icons'

import {
  faSackDollar as freeFasFaSackDollar,
  faPercent as freeFasFaPercent,
  faChartLine as freeFasFaChartLine,
  faBuildingColumns as freeFasFaBuildingColumns,
  faBuildingLock as freeFasFaBuildingLock,
  faBuilding as freeFasFaBuilding,
  faFileInvoice as freeFasFaFileInvoice,
  faSquareRss as freeFasFaSquareRss,
  faBuildingShield as freeFasFaBuildingShield,
  faArrowTrendUp as freeFasFaArrowTrendUp,
  faPeopleGroup as freeFasFaPeopleGroup,
  faClockRotateLeft as freeFasFaClockRotateLeft
} from '@fortawesome/free-solid-svg-icons'

import {
  faBitcoin as freeFabFaBitcoin,
  faSquareFacebook as freeFabFaSquareFacebook,
  faSquareTwitter as freeFabFaSquareTwitter,
  faLinkedin as freeFabFaLinkedin
} from '@fortawesome/free-brands-svg-icons'

library.add(
  freeFarFaMoneyBill1,
  freeFarFaFileLines,
  freeFasFaSackDollar,
  freeFasFaPercent,
  freeFasFaChartLine,
  freeFasFaBuildingColumns,
  freeFasFaBuildingLock,
  freeFasFaBuilding,
  freeFasFaFileInvoice,
  freeFasFaSquareRss,
  freeFasFaBuildingShield,
  freeFasFaArrowTrendUp,
  freeFasFaPeopleGroup,
  freeFasFaClockRotateLeft,
  freeFabFaBitcoin,
  freeFabFaSquareFacebook,
  freeFabFaSquareTwitter,
  freeFabFaLinkedin
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
