import { extend, configure } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'This field is required',
})

extend('validMoney', {
  validate: (value) => {
    return value > 0
  },
  message: 'Please enter a valid amount',
})

extend('requiredDealExpiration', {
  ...required,
  message: 'This field is required or check "Evergreen" checkbox',
})

extend('requiredTrackRecord', {
  ...required,
  message: 'This field is required or check "New Firm" checkbox',
})

extend('NewFirmOrGreaterThanZero', {
  validate: (value) => {
    return value > 0 || value === 'New Firm'
  },
  message: 'Please enter a valid amount or check "New Firm" checkbox',
})

configure({
  classes: {
    invalid: 'border-red-500',
  },
})
