import Cookie from 'cookie-universal'
import { v4 as uuidv4 } from 'uuid'
import { CookieSerializeOptions } from 'cookie'
import { IUser } from '~/types'

const COOKIE_NAME = 'flaia_id'
const ANALYTICS_ENDPOINT = 'stats.flaia.org'

export interface ITrackOptions {
  referrer?: string
  anonymous_id?: string
  user?: IUser
  eventData?: any
  ip?: string
  userAgent?: string
}

const HEARTBEAT_FALLOFF = {
  0: 1,
  10: 5,
  60: 10,
  300: 30,
}
export const startHeartbeat = (event: string, url: string, options: ITrackOptions) => {
  let totalDuration = 0
  let timeout: number

  const fn = (duration: number) => {
    options.eventData.duration = duration
    trackEvent(event, url, options)
    totalDuration += duration

    const nextBeat = Object.entries(HEARTBEAT_FALLOFF).reduce((acc, [k, v]) => {
      if (totalDuration >= parseInt(k) && v > acc) return v
      return acc
    }, 0)
    timeout = window.setTimeout(() => fn(nextBeat), nextBeat * 1000)
  }

  timeout = window.setTimeout(() => fn(1), 1000)

  return () => {
    clearTimeout(timeout)
  }
}

export const trackEvent = (event: string, url: string, options: ITrackOptions) => {
  const cookies = Cookie()
  let anonymous_id = options.anonymous_id || cookies.get(COOKIE_NAME)

  if (!anonymous_id) {
    const cookieParams: CookieSerializeOptions = {
      sameSite: 'strict',
      path: '/',
      maxAge: 2147483647, // Y-2038
      domain: process.env.cookieDomain,
    }
    if (process.env.production) {
      cookieParams.secure = true
    }

    anonymous_id = uuidv4()
    cookies.set(COOKIE_NAME, anonymous_id, cookieParams)
  }

  const body: any = {
    url,
    referrer: options.referrer,
    anonymous_id,
    event_type: event,
  }

  body.ip = options.ip
  body.user_agent = options.userAgent
  body.eventData = options.eventData

  if (options.user) {
    body.user = sanitizeUser(options.user)
  }

  return postEvent(body)
}

function postEvent(body: any) {
  if (process.server) {
    fetch(`https://${ANALYTICS_ENDPOINT}/collect/`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    }).catch((err) => {
      if (process.server) {
        console.error(JSON.stringify(err))
      }
    })
  } else {
    fetch(`https://${ANALYTICS_ENDPOINT}/collect/`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
      keepalive: true,
    })
    // navigator.sendBeacon(`https://${ANALYTICS_ENDPOINT}/collect/`, JSON.stringify(body))
  }
}

function sanitizeUser(user: IUser): Partial<IUser> {
  const cleanUser: Partial<IUser> = Object.assign({}, user)
  delete cleanUser.role
  delete cleanUser.provider
  delete cleanUser.email
  delete cleanUser.phone_number
  delete cleanUser.confirmed
  delete cleanUser.blocked
  delete cleanUser.created_at
  delete cleanUser.updated_at

  if (cleanUser.company !== null && cleanUser.company !== undefined && typeof cleanUser.company === 'object') {
    cleanUser.company = cleanUser.company.id
  }

  if (cleanUser.speaker_profile !== null && cleanUser.speaker_profile !== undefined && typeof cleanUser.speaker_profile === 'object') {
    cleanUser.speaker_profile = cleanUser.speaker_profile.id
  }

  return cleanUser
}
