import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
// @ts-ignore
import { getField, updateField } from 'vuex-map-fields'
import { DateTime } from 'luxon'
import {
  ICompany,
  IEvent,
  IEventType,
  IKeynote,
  IPanel,
  IPreRegistrationQuestion,
  ISpeakerProfile,
  IStrapiImage,
  IUnsplashImage,
  IWebcast,
} from '~/types'

const steps: ((e: EditorStore) => boolean)[] = [
  (e) => !!e.title, // Has Title
  (e) => e.headerImage.length > 0, // Has Header Image
  (e) => !!e.date, // Has Date
  (e) => !!e.startTime, // Has Start Time
  (e) => e.eventType.length > 0 && e.eventType[0] !== null, // Has Event Type
  (e) => !!e.description, // Has Description
  (e) => e.bulletItems.length > 0 && e.bulletItems[0] !== null && e.bulletItems[0].text !== '', // Has at least one bullet
  (e) => Object.keys(e.speakers).length > 0, // Has at least one speaker
]

const progressMap = [10, 10, 20, 10, 10, 20, 10, 10]
const labelsMap = [
  'Enter an Event Title',
  'Add a Header Image',
  'Choose a Date',
  'Choose a Start Time',
  'Choose an Event Type',
  'Add a Description',
  'Add some Bullet Points',
  'Add some Speakers',
]

@Module({
  name: 'editor',
  stateFactory: true,
  namespaced: true,
})
export default class EditorStore extends VuexModule {
  title: string = ''
  date: Date | null = null
  startTime: string = ''
  endTime: string = ''
  description: string = ''
  bulletItems: { text: string }[] = []
  headerImage: [Omit<IStrapiImage, 'id'> | Omit<IUnsplashImage, 'id'>] | [] = []
  preRegistrationQuestions: Omit<IPreRegistrationQuestion, 'id'>[] = []
  company: number | ICompany | null = null
  eventType: [IEventType] | [] = []
  company_pdf: any
  company_pdf_file: any[] = []
  company_investment_url = ''
  company_data: any = {
    speakersIDs: [],
    company_id: null,
    company_url: null,
  }

  editingQuestion: number | null = null

  get getField() {
    return getField(this)
  }

  @Mutation
  updateField(options: { path: string; value: unknown }) {
    return updateField(this, options)
  }

  @Mutation
  SET_TITLE(title: string) {
    this.title = title
  }

  @Mutation
  SET_COMPANY_DATA(company_data: any) {
    if (company_data.type === 'id') this.company_data.company_id = company_data.data
    if (company_data.type === 'url') this.company_data.company_url = company_data.data
  }

  @Mutation
  SET_COMPANY_URL(url: string) {
    this.company_investment_url = url
  }

  @Mutation
  SET_DATE(date: Date) {
    this.date = date
  }

  @Mutation
  SET_START_TIME(startTime: string) {
    this.startTime = startTime
  }

  @Mutation
  SET_END_TIME(endTime: string) {
    this.endTime = endTime
  }

  @Mutation
  SET_DESCRIPTION(description: string) {
    this.description = description
  }

  @Mutation
  SET_BULLET_ITEMS(bulletItems: { text: string }[]) {
    this.bulletItems = bulletItems
  }

  @Mutation
  SET_HEADER_IMAGE(headerImage: [Omit<IStrapiImage, 'id'> | Omit<IUnsplashImage, 'id'>] | []) {
    this.headerImage = headerImage
  }

  @Mutation
  SET_COMPANY_PDF(url: string) {
    this.company_pdf = url
  }

  @Mutation
  SET_COMPANY_PDF_FILE(file: FileList, title: string) {
    this.company_pdf_file.push({
      title,
      media: file,
    })
  }

  @Mutation
  SET_PRE_REGISTRATION_QUESTIONS(preRegistrationQuestions: IPreRegistrationQuestion[]) {
    this.preRegistrationQuestions = preRegistrationQuestions
  }

  @Mutation
  SET_COMPANY(company: number) {
    this.company = company
  }

  @Mutation
  SET_EVENT_TYPE(eventType: [IEventType] | []) {
    this.eventType = eventType
  }

  @Mutation
  ADD_SPEAKER({ index, speaker }: { index: number | 'moderator'; speaker: ISpeakerProfile }) {
    if (this.eventType === null) {
      return
    }

    switch (this.eventType[0]?.__component) {
      case 'event-types.keynote':
        Vue.set(this.eventType[0], 'speaker', speaker)
        break
      case 'event-types.webcast':
        Vue.set(this.eventType[0].speakers, index, speaker)
        break
      case 'event-types.panel': {
        if (index === 'moderator') {
          Vue.set(this.eventType[0], 'moderator', speaker)
        } else {
          Vue.set(this.eventType[0].panelists, index, speaker)
        }
        break
      }
    }
  }

  @Mutation
  REMOVE_SPEAKER(index: number | 'moderator') {
    if (this.eventType === null) {
      return
    }

    switch (this.eventType[0]?.__component) {
      case 'event-types.keynote':
        Vue.delete(this.eventType[0], 'speaker')
        break
      case 'event-types.webcast':
        Vue.delete(this.eventType[0].speakers, index)
        break
      case 'event-types.panel': {
        if (index === 'moderator') {
          Vue.delete(this.eventType[0], 'moderator')
        } else {
          Vue.delete(this.eventType[0].panelists, index)
        }
        break
      }
    }
  }

  @Mutation
  MOVE_QUESTION_UP(index: number) {
    if (this.preRegistrationQuestions === undefined) return
    if (index === 0 || this.preRegistrationQuestions?.length < 2) return

    const first = this.preRegistrationQuestions[index - 1]
    const second = this.preRegistrationQuestions[index]

    Vue.set(this.preRegistrationQuestions, index, first)
    Vue.set(this.preRegistrationQuestions, index - 1, second)
  }

  @Mutation
  MOVE_QUESTION_DOWN(index: number) {
    if (index === this.preRegistrationQuestions.length || this.preRegistrationQuestions.length < 2) return

    const first = this.preRegistrationQuestions[index + 1]
    const second = this.preRegistrationQuestions[index]

    Vue.set(this.preRegistrationQuestions, index, first)
    Vue.set(this.preRegistrationQuestions, index + 1, second)
  }

  @Mutation
  START_QUESTION_EDIT(index: number) {
    this.editingQuestion = index
  }

  @Mutation
  STOP_QUESTION_EDIT() {
    this.editingQuestion = null
  }

  @Mutation
  DELETE_QUESTION(index: number) {
    Vue.delete(this.preRegistrationQuestions, index)
  }

  @Mutation
  ADD_QUESTION({ index, question }: { index: number; question: Omit<IPreRegistrationQuestion, 'id'> }) {
    Vue.set(this.preRegistrationQuestions, index, question)
  }

  @Action
  setEvent(event: IEvent) {
    this.SET_TITLE(event.title)
    this.SET_DATE(event.date)
    this.SET_START_TIME(event.start_time)
    this.SET_END_TIME(event.end_time)
    this.SET_DESCRIPTION(event.description)
    this.SET_HEADER_IMAGE(event.header_image)
    this.SET_COMPANY_PDF(event.company_pdf)
    this.SET_COMPANY_DATA(event.company)

    if (event.event_type[0].__component === 'event-types.keynote' && this.date && this.startTime && this.endTime) {
      const start = DateTime.fromISO(`${this.startTime}`)
      const end = DateTime.fromISO(`${this.endTime}`)
      const duration = end.diff(start)
      if (duration.as('minutes') === 30) event.event_type[0].duration = 30
      if (duration.as('minutes') === 60) event.event_type[0].duration = 60
    }
    this.SET_EVENT_TYPE(event.event_type)

    const bulletItems = event.bullet_items.filter((i) => i.text !== undefined) as { text: string }[]
    this.SET_BULLET_ITEMS(bulletItems)

    if (event.pre_registration_questions) this.SET_PRE_REGISTRATION_QUESTIONS(event.pre_registration_questions)
    if (event.company) this.SET_COMPANY(event.company.id)
  }

  @Action
  setEventTime(date: Date | null) {
    if (date === null) {
      this.SET_START_TIME('')
      return
    }
    this.SET_START_TIME(DateTime.fromJSDate(date).toISOTime({ includeOffset: false }))
  }

  @Action
  changeEventType(type: string) {
    switch (type) {
      case 'Keynote (30 min)':
        this.SET_EVENT_TYPE([
          {
            __component: 'event-types.keynote',
            // Keep speaker if onyl duration changes
            speaker: this.eventType[0]?.__component === 'event-types.keynote' ? this.eventType[0]?.speaker : undefined,
            duration: 30,
          },
        ] as [IKeynote])
        break
      case 'Keynote (60 min)':
        this.SET_EVENT_TYPE([
          {
            __component: 'event-types.keynote',
            // Keep speaker if onyl duration changes
            speaker: this.eventType[0]?.__component === 'event-types.keynote' ? this.eventType[0]?.speaker : undefined,
            duration: 60,
          },
        ] as [IKeynote])
        break
      case 'Webcast':
        this.SET_EVENT_TYPE([
          {
            __component: 'event-types.webcast',
            speakers: [],
          },
        ] as unknown as [IWebcast])
        break
      case 'Panel':
        this.SET_EVENT_TYPE([
          {
            __component: 'event-types.panel',
            moderator: undefined,
            panelists: [],
          },
        ] as unknown as [IPanel])
        break
    }
  }

  get eventStartTime(): Date {
    if (this.startTime === '') {
      const minutes = Math.ceil(DateTime.local().minute / 5) * 5
      let newTime = DateTime.local()
      newTime = newTime.set({ minute: minutes })
      return newTime.toJSDate()
    }
    return DateTime.fromISO(this.startTime).toJSDate()
  }

  get eventDuration(): number | null {
    if (this.startTime === null || this.eventTypeName === null) {
      return null
    }
    const duration: any = {
      Webcast: 60,
      'Keynote (30 min)': 30,
      'Keynote (60 min)': 60,
      Panel: 60,
    }
    return duration[this.eventTypeName]
  }

  get eventEndTime(): string {
    if (this.eventDuration === null) {
      return ''
    }

    const date = DateTime.fromISO(this.startTime).plus({ minute: this.eventDuration })
    return date.toLocaleString(DateTime.TIME_SIMPLE)
  }

  get eventTypeName(): string | null {
    if (this.eventType === null) {
      return null
    }

    switch (this.eventType[0]?.__component) {
      case 'event-types.keynote':
        if (this.eventType[0]?.duration === 30) return 'Keynote (30 min)'
        if (this.eventType[0]?.duration === 60) return 'Keynote (60 min)'
        return 'Keynote (30 min)'
      case 'event-types.webcast':
        return 'Webcast'
      case 'event-types.panel':
        return 'Panel'
      default:
        return null
    }
  }

  get speakers(): { [key: number]: ISpeakerProfile; moderator?: ISpeakerProfile } {
    if (this.eventType === null) {
      return {}
    }

    switch (this.eventType[0]?.__component) {
      case 'event-types.keynote':
        if (this.eventType[0].speaker === undefined) return {}
        return { 0: this.eventType[0].speaker }
      case 'event-types.webcast':
        return Object.assign({}, this.eventType[0].speakers)
      case 'event-types.panel': {
        const speakers: { [key: number]: ISpeakerProfile; moderator?: ISpeakerProfile } = Object.assign({}, this.eventType[0].panelists)
        if (this.eventType[0].moderator !== undefined) {
          speakers.moderator = this.eventType[0].moderator
        }
        return speakers
      }
      default:
        return {}
    }
  }

  get currentStep() {
    for (const [i, func] of steps.entries()) {
      if (!func(this)) {
        return i + 1
      }
    }
    return steps.length + 1
  }

  get progressLabel() {
    return labelsMap[this.currentStep - 1]
  }

  get progressPercent(): number {
    let progress = 0
    for (const [i, func] of steps.entries()) {
      if (func(this)) {
        progress += progressMap[i]
      }
    }
    return progress
  }

  get httpParams() {
    return {
      title: this.title,
      date: this.date,
      start_time: this.startTime,
      description: this.description,
      bullet_items: this.bulletItems,
      header_image: this.headerImage,
      company_pdf: this.company_pdf,
      company_data: this.company_data,
      pre_registration_questions: this.preRegistrationQuestions,
      company: this.company,
      event_type: this.eventType,
    }
  }

  @Action
  resetEditor() {
    this.SET_TITLE('')
    this.SET_START_TIME('')
    this.SET_DESCRIPTION('')
    this.SET_BULLET_ITEMS([])
    this.SET_HEADER_IMAGE([])
    this.SET_PRE_REGISTRATION_QUESTIONS([])
    this.SET_EVENT_TYPE([])
  }
}
